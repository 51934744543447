module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"a6c809ae38fd4117abebd6df757d215c","source_map_enabled":true,"captureUncaught":true,"captureUnhandledRejections":true,"verbose":false,"scrubTelemetryInputs":true,"payload":{"environment":"production","client":{"javascript":{"source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-KXLFW2Q","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","staging"]},
    }]
